/** Expandable content works without JavaScript.
 *  ------------------------------------------------------------------------------------------------
 *  It relies more on the css /styles/site/components/expandable-content.css
 *  this module just adds a few nice to haves for accesibility and allows for more variety in
 *  the markup.
 *  Default markup for expandable content:
<div class="expandable-content expandable-content--checkbox">
  <input class="visuallyhidden--always" id="123" type="checkbox">
  <label class="expandable-content-trigger" for="123"><span class="button">Click Me</span></label>
  <div class="expandable-content__content">...content here</div>
</div>
**/

/** focus the buttons for expandable content when the relevant checkbox is focused
 *  ------------------------------------------------------------------------------------------------
**/

const expandableCheckFocus = (e) => {
	// console.log(e.currentTarget.id);
	const target = document.querySelectorAll(`label[for='${e.currentTarget.id}']`);
	// console.log(target);

	(target || []).forEach(
		(label) => {
			label.classList.add('expandable-content-trigger--input-has-focus');
		},
	);
};

const expandableCheckBlur = (e) => {
	const target = document.querySelectorAll(`label[for='${e.currentTarget.id}']`);

	(target || []).forEach(
		(label) => {
			label.classList.remove('expandable-content-trigger--input-has-focus');
		},
	);
};

const expandableCheckChange = (e) => {
	const target = document.querySelectorAll(`label[for='${e.currentTarget.id}']`);

	if (e.currentTarget.checked) {
		(target || []).forEach(
			(label) => {
				label.classList.add('expandable-content-trigger--input-is-active');
			},
		);
	} else {
		(target || []).forEach(
			(label) => {
				label.classList.remove('expandable-content-trigger--input-is-active');
			},
		);
	}
};

/** Initialise listeners
 *  ------------------------------------------------------------------------------------------------
**/
export const init = () => {
	// if we want legacy support for :focus-within to expand content we can use JS for that here...
	// const expandableContent = document.querySelectorAll('.expandable-content__content');
	// (expandableContent || []).forEach(
	// (content) => {
	// // listen for focus inside
	// },
	// );

	const expandableCheckboxes = document.querySelectorAll('.expandable-content > input[type=checkbox], .expandable-content__content--isolated > input[type=checkbox]');
	(expandableCheckboxes || []).forEach(
		(check) => {
			check.addEventListener('focus', expandableCheckFocus, false);
			check.addEventListener('blur', expandableCheckBlur, false);
			check.addEventListener('change', expandableCheckChange, false);
		},
	);
};

export default init;
